import React, { Component } from 'react'

export default class Presentations extends Component {
  render() {
    return (
      <div>
				<section className="colorlib-work" data-section="presentations">
					<div className="colorlib-narrow-content">
						<div className="row">
							<div className="col-md-6 col-md-offset-3 col-md-pull-3 animate-box" data-animate-effect="fadeInLeft">
								<span className="heading-meta">My Public Work</span>
								<h2 className="colorlib-heading animate-box">Public Research and Presentations</h2>
							</div>
						</div>
						<div className="row">
							<div className="col-md-4 animate-box" data-animate-effect="fadeInLeft">
								<div className="project">
									<div className="desc">
										<div className="con">
											<h3><a href="https://quora.org/pres/golangjs.pdf">Golang and Javascript: The Future of Cloud</a></h3>
											<span>This talk addresses cloud application design choices and navigates the viewer through the benefits and pitfalls</span>
										</div>
									</div>
								</div>
							</div>
							<div className="col-md-4 animate-box" data-animate-effect="fadeInRight">
								<div className="project">
									<div className="desc">
										<div className="con">
											<h3><a href="https://quora.org/pres/great-wifi/">Making WiFi Great</a></h3>
											<span>This talk gives the viewer an overview of what makes WiFi work poor, and offers practical advice on setup and tuning to achieve great WiFi</span>
										</div>
									</div>
								</div>
							</div>
							<div className="col-md-4 animate-box" data-animate-effect="fadeInRight">
								<div className="project">
									<div className="desc">
										<div className="con">
											<h3><a href="https://quora.org/pres/scaling-x86.pdf">Breaking the Limits of x86</a></h3>
											<span>This talk introduces large-scale (hundreds of cores) computing systems, Linux kernel changes needed and challenges</span>
										</div>
									</div>
								</div>
							</div>
							<div className="col-md-4 animate-box" data-animate-effect="fadeInTop">
								<div className="project">
									<div className="desc">
										<div className="con">
											<h3><a href="https://quora.org/agung.pdf">Mount Agung Volunteering</a></h3>
											<span>Proposal to deploy WiFi in the main Mount Agung evacuation centre</span>
										</div>
									</div>
								</div>
							</div>
							<div className="col-md-4 animate-box" data-animate-effect="fadeInLeft">
								<div className="project">
									<div className="desc">
										<div className="con">
											<h3><a href="https://quora.org/edu/report.pdf">Interactive Learning Platform</a></h3>
											<span>Development of a Raspberry Pi 3 powered triple-touchscreen computer for edutainment</span>
										</div>
									</div>
								</div>
							</div>
							<div className="col-md-4 animate-box" data-animate-effect="fadeInLeft">
								<div className="project">
									<div className="desc">
										<div className="con">
											<h3><a href="https://quora.org/thesis-daniel-blueman.pdf">Chromatic Aberration Recovery on Arbitrary Images</a></h3>
											<span>MSc by Research Thesis</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
      </div>
    )
  }
}
