import React, { Component } from 'react'

export default class Projects extends Component {
  render() {
    return (
      <div>
				<section className="colorlib-work" data-section="projects">
					<div className="colorlib-narrow-content">
						<div className="row">
							<div className="col-md-6 col-md-offset-3 col-md-pull-3 animate-box" data-animate-effect="fadeInLeft">
								<span className="heading-meta">My Public Work</span>
								<h2 className="colorlib-heading animate-box">Open Source Projects</h2>
							</div>
						</div>
						<div className="row">
							<div className="col-md-4 animate-box" data-animate-effect="fadeInLeft">
								<div className="project">
									<div className="desc">
										<div className="con">
											<h3><a href="https://github.com/numascale/numascope">NUMAscope</a></h3>
											<span>Realtime collection and live graphing of on-chip cache coherent events, using Golang and JavaScript</span>
										</div>
									</div>
								</div>
							</div>
							<div className="col-md-4 animate-box" data-animate-effect="fadeInRight">
								<div className="project">
									<div className="desc">
										<div className="con">
											<h3><a href="https://github.com/numascale/firmware">NumaConnect2 firmware</a></h3>
											<span>Firmware to boot servers as one large cache-coherent system, gen 2 interconnect</span>
										</div>
									</div>
								</div>
							</div>
							<div className="col-md-4 animate-box" data-animate-effect="fadeInTop">
								<div className="project">
									<div className="desc">
										<div className="con">
											<h3><a href="https://github.com/numascale/nc-utils/tree/master/numaplace">NUMAplace</a></h3>
											<span>Workload dynamic placement tool</span>
										</div>
									</div>
								</div>
							</div>
							<div className="col-md-4 animate-box" data-animate-effect="fadeInBottom">
								<div className="project">
									<div className="desc">
										<div className="con">
											<h3><a href="https://github.com/numascale/legacy-firmware">NumaConnect firmware</a></h3>
											<span>Firmware to boot servers as one large cache-coherent system, gen 1 interconnect</span>
										</div>
									</div>
								</div>
							</div>
							<div className="col-md-4 animate-box" data-animate-effect="fadeInLeft">
								<div className="project">
									<div className="desc">
										<div className="con">
											<h3><a href="https://github.com/dblueman/autorobo">AutoRobo</a></h3>
											<span>Modular robotics platform</span>
										</div>
									</div>
								</div>
							</div>
							<div className="col-md-4 animate-box" data-animate-effect="fadeInLeft">
								<div className="project">
									<div className="desc">
										<div className="con">
											<h3><a href="https://resources.numascale.com/kernels/current/patches/0030-clocktree6.patch">Clocktree 6 Linux Kernel clocksource</a></h3>
											<span>Hierarchical and scalable Linux kernel timekeeping mechanism</span>
										</div>
									</div>
								</div>
							</div>
							<div className="col-md-4 animate-box" data-animate-effect="fadeInLeft">
								<div className="project">
									<div className="desc">
										<div className="con">
											<h3><a href="https://resources.numascale.com/configurator.html">NumaConnect2 configurator</a></h3>
											<span>Interactive Javascript cache-coherent interconnect fabric configurator</span>
										</div>
									</div>
								</div>
							</div>
							<div className="col-md-4 animate-box" data-animate-effect="fadeInLeft">
								<div className="project">
									<div className="desc">
										<div className="con">
											<h3><a href="https://open-school.net">Open School</a></h3>
											<span>Project to help schools deploy sustainable and robust computing resources</span>
										</div>
									</div>
								</div>
							</div>
							<div className="col-md-4 animate-box" data-animate-effect="fadeInLeft">
								<div className="project">
									<div className="desc">
										<div className="con">
											<h3><a href="https://summit.fossasia.org">FOSSASIA OpenTech Summit</a></h3>
											<span>Organising and running an annual tech summit in Singapore</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
      </div>
    )
  }
}
